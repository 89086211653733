.project-card{
    width:350px;
    color:white;
    background-color: #242424;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 10px;
    padding: 20px;
    border: 2px solid #242424;
    transition: background-color 0.2s ease-in-out;
    justify-content:space-around;
    position: relative;
    visibility: none;
    margin: 0 auto;
}

.project-card h1{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px;
    font-size: 25px;
    margin-right: 10px;
}

.project-card p{
    font-size: 20px;
    line-height: 1.7;
    margin-top:20px;
    
    
}

.card-info{
    display: grid;
}

.project-card img{
    width: 100%;
    aspect-ratio: 1;
    object-fit:contain;
}

.project-card:hover{
    cursor: pointer;
    background-color: #474747;
}

.see-more{
    color:#242424;
    font-weight: bold;
    float: right;
    padding: 0;
    margin: 10px;
    bottom: 0;
    right:0;
    width:25px;
    position: absolute;
    text-align: end;
    padding: 3px 10px;
    border-radius: 10px;
    transform: scaleX(-100%);
    
}
.project-card:hover > .see-more{
    display: block;
}

.desc-text{
    border-left: 5px solid #5e5e5e;
    padding:0px 20px 20px 20px;
    text-align: start;
    top:0px;
    margin-top: 0;
}


.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
    animation-duration: 0.6s;
    animation-fill-mode: 'forwards',
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
        visibility: hidden; 
	}
	100% {
		opacity: 1;
        visibility: visible; 
	}
}


@media only screen and (max-width: 750px) {
    .project-card{
        width:80%;
        margin: auto;
    }
}

@media only screen and (max-width:700px){
    .project-card p{
        font-size: 15px;
    }
}