.ProjectSelector{
    padding: 0px;
    margin: 0px;
    text-align: center;
    margin-bottom: 30px;
    position: sticky;
}

.ProjectSelector > div:hover{
    cursor: pointer;
    background-color:
    rgba(255, 255, 255, 0.2);
}

.Selected{
    border:2px solid white;
    background-color: rgba(200, 200, 200, 0.5);
    color: white;
}

.NotSelected{
    border:2px solid transparent;
    background-color:
    rgba(255, 255, 255, 0.1);
    color: rgba(130,130,130,1)
}

.ProjectSelector > div{
    padding: 0px;
    display: inline-block;

    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;

    width: 100px;

    font-weight: bold;
    font-size: 10px;



    box-shadow: 0px 7px rgba(255, 255, 255, 0.05);

    margin-top: 20px;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}



.ProjectSelector > div:not(:last-child){
    margin-right: 10px;
}

.filter{
    width: 30px;
    vertical-align: middle;
    margin-right: 10px;
}



@media only screen and (max-width: 800px){
    .ProjectSelector{
        margin: auto;
        width: 65%;
    }
}